import { Injectable } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Subject } from 'rxjs';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { map, catchError} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router, Params } from '@angular/router';

@Injectable()
export class AuthService {
  public isLoggedIn = false;
  public accessToken;
  public name = "Guest";
  public profile = 0;

  public version = '3.35';

  public searchData = null;
  public goUrl = null;
  
  public apiUrl = 'https://api.ishipto.vn';

  //public apiUrl = 'http://localhost:8080';
  
  // store the URL so we can redirect after logging in
  public redirectUrl: string;

  constructor(
    public afAuth: AngularFireAuth,
    private _http:Http,
    private _httpClient: HttpClient, 
    private router: Router,
  ){}
  
  public login(data): void{
    console.log(data);
    this.isLoggedIn = true;
    this.accessToken = data.accessToken;
    this.name = data.name;
    this.profile = data.profile;
  }
  public logout(): void {
    this.isLoggedIn = false;
    this.accessToken = null;
    this.name = 'Guest';
    this.profile = 0;
    this.router.navigate(['authentication/login-2']);
  }

  doFacebookLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    })
  }

  doTwitterLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }

  doGoogleLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }

  doRegister(value){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }

  doLogin(value){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }

  doLogout(){
    return new Promise((resolve, reject) => {
      if(firebase.auth().currentUser){
        this.afAuth.auth.signOut();
        this.logout();
        resolve();
      }
      else{
        reject();
      }
    });
  }

  loginByFb(token): Observable<any> {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': token
        })
      };
      return this._httpClient.get(this.apiUrl + '/facebook', httpOptions)
        .pipe(
          map(this.extractData),
          catchError(this.handleError)
        )
    }
    private extractData(res: Response) {
      let body = res;
      return body;
    }
    private handleError (error: Response | any) {
      console.error(error.message || error);
      return throwError(error.status);
    }
}
