import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { SeaportService } from 'app/main/service/seaport.service';
import { CountryService } from 'app/main/service/country.service';
import { PackageTypeService } from 'app/main/service/package-type.service';
import { AuthService } from 'app/auth.service';

import { VoyageService } from 'app/main/service/voyage.service';
import { VesselService } from 'app/main/service/vessel.service';
import { SupplierService } from 'app/main/service/supplier.service';
import { CurrencyService } from 'app/main/service/currency.service';
import { SeaTerminalService } from 'app/main/service/sea-terminal.service';
import { FclPriceService } from 'app/main/service/fcl-price.service';
import { PackageSettingService } from 'app/main/service/package-setting.service';
import { UserSearchService } from 'app/main/service/user-search.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HomeService implements Resolve<any>
{
    data: any[];
    widgets: any[];
    search;
    searchActive = false;
    result = [];
    voyage;
    pol;
    pod;
    port;
    shortcut: any[];
    onHomeChanged: BehaviorSubject<any>;
    onHomeResultChanged: BehaviorSubject<any>;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private seaportService: SeaportService,
        private countrySerivce: CountryService,
        private packageTypeService: PackageTypeService,
        private voyageService: VoyageService,
        private vesselService: VesselService,
        private supplierService: SupplierService,
        private currencyService: CurrencyService,
        private seaTerminalService: SeaTerminalService,
        private fclPriceService: FclPriceService,
        private userSearchService: UserSearchService,
        private packageSettingService: PackageSettingService,
        private authService: AuthService
    )
    {

        this.onHomeChanged = new BehaviorSubject({});
        this.onHomeResultChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.seaportService.getSeaport(),
                this.countrySerivce.getCountry(),
                this.packageTypeService.getPackageType(),
                this.currencyService. getCurrency(),
                this.packageSettingService.getPackageSetting()
            ]).then(
                (data) => {
                    console.log(data);
                    let length = data[0].length
                    for(let i = 0; i < length; i++){
                        data[0][i].info = JSON.parse(data[0][i].info);
                    }
                    this.data = data;
                    this.port = data[0];
                    this.onHomeChanged.next(data);
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get widgets
     *
     * @returns {Promise<any>}
     */
    getWidgets(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/analytics-dashboard-widgets')
                .subscribe((response: any) => {
                    this.widgets = response;
                    resolve(response);
                }, reject);
        });
    }
    getVoyageByPortId(polId, podId, etd): Promise<any>{
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-voyage-list/port/' + polId + '/' + podId + '/' + etd)
                .subscribe((response: any) => {
                    /*this.voyage = response;
                    this.onVoyageChanged.next(response);
                    */
                    resolve(response);
                }, reject);
        });
    }
    getSearchFclPrice(id): Promise<any>{
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-user/shortcut/' + id, httpOptions)
                .subscribe((response: any) => {
                    this.shortcut = response;
                    resolve(response);
                }, reject);
        });
    }
    getSearchData(etd): Promise<any>{
        let pol = this.pol;
        let pod = this.pod;
        return new Promise((resolve, reject) => {
            Promise.all([
                //voyage
                this.voyageService.getVoyageByPortId(pol.id, pod.id, etd),
                this.vesselService.getVessel(),
                this.supplierService.getSupplier(),
                this.seaTerminalService.getSeaTerminal(),
                
            ]).then(
                (data) => {
                    data[0].map(item => {
                        item.supplier = data[2].find(a =>a.id == item.supplier_id);
                        item.vessel = data[1].find(a =>a.id == item.vessel_id);
                        item.pol = pol;
                        item.load_terminal = data[3].find(a =>a.id == item.load_terminal);
                        item.pod = pod;
                        item.discharge_terminal = data[3].find(a =>a.id == item.discharge_terminal);
                        item.depart_time = new Date(item.depart_time);
                        item.arrive_time = new Date(item.arrive_time);
                        item.transit_time = Math.round(Math.abs((item.arrive_time.getTime() - item.depart_time.getTime())/(24*60*60*1000)));
                        if(item.FclInfo){
                            item.units = item.FclInfo.split(',');
                        }
                    });
                    this.result = data[0];
                    this.searchActive = true;
                    this.onHomeResultChanged.next(this.result);
                    resolve(data);
                },
                reject
            );
        });
    }

    getSearchDataById(polId, podId, etd): Promise<any>{
        return new Promise((resolve, reject) => {
            Promise.all([
                //voyage
                this.voyageService.getVoyageByPortId(polId, podId, etd),
                this.vesselService.getVessel(),
                this.supplierService.getSupplier(),
                this.seaTerminalService.getSeaTerminal(),
                this.seaportService.getSeaport(),
            ]).then(
                (data) => {
                    let length = data[4].length
                    for(let i = 0; i < length; i++){
                        data[4][i].info = JSON.parse(data[4][i].info);
                    }

                    data[0].map(item => {
                        item.supplier = data[2].find(a =>a.id == item.supplier_id);
                        item.vessel = data[1].find(a =>a.id == item.vessel_id);
                        item.pol = data[4].find(a =>a.id == item.pol);
                        item.load_terminal = data[3].find(a =>a.id == item.load_terminal);
                        item.pod = data[4].find(a =>a.id == item.pod);
                        item.discharge_terminal = data[3].find(a =>a.id == item.discharge_terminal);
                        item.depart_time = new Date(item.depart_time);
                        item.arrive_time = new Date(item.arrive_time);
                        item.transit_time = Math.round(Math.abs((item.arrive_time.getTime() - item.depart_time.getTime())/(24*60*60*1000)));
                        
                        if(item.FclInfo){
                            item.units = item.FclInfo.split(',');
                        }
                    });
                    this.result = data[0];
                    this.port = data[4];
                    this.pol = data[4].find(a =>a.id == polId);
                    this.pod = data[4].find(a =>a.id == podId);
                    this.searchActive = true;
                    this.onHomeResultChanged.next(this.result);
                    resolve(data);
                },
                reject
            );
        });
    }

    
}
