import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { FclService } from 'app/main/service/fcl.service';
import { PackageSettingService } from 'app/main/service/package-setting.service';
import { CurrencyService } from 'app/main/service/currency.service';
import { PriceService } from 'app/main/service/price.service';
import { SeaportService } from 'app/main/service/seaport.service';
import { FclVoyageListService } from 'app/main/service/fcl-voyage-list.service';
import { VesselService } from 'app/main/service/vessel.service';
import { VoyageService } from 'app/main/service/voyage.service';

@Injectable()
export class FclInfoService implements Resolve<any>
{
    id;
    fclInfo;
    dataCreate;
    dataUpdate;
    dataDelete;
    fclPrice: any[];
    onFclPriceChanged: BehaviorSubject<any>;
    onFclInfoPriceChanged: BehaviorSubject<any>;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private fclService: FclService,
        private packageService: PackageSettingService,
        private currencyService: CurrencyService,
        private priceService: PriceService,
        private seaportService: SeaportService,
        private fclVoyageListService: FclVoyageListService,
        private vesselService: VesselService,
        private voyageService: VoyageService,
        private router: Router
    )
    {
        // Set the defaults
        this.onFclPriceChanged = new BehaviorSubject({});
        this.onFclInfoPriceChanged = new BehaviorSubject({});
        
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.id = route.params.id;
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getFclPriceByFclId(this.id),
                this.fclService.getFclById(this.id),
                this.packageService.getPackageSetting(),
                this.currencyService. getCurrency(),
                this.priceService.getPrice(),
            ]).then(
                (data) => {
                    data[0].map(item => {
                        item.price = data[4].find(a => a.id == item.price);
                        item.unit = data[2].find(a => a.id == item.unit);
                        item.currency = data[3].find(a => a.id == item.currency);
                    });
                    this.fclInfo = data;
                    this.onFclInfoPriceChanged.next(data);
                    resolve(data);
                },
                reject
            );
        });
    }

    /**
     * Get fclPrice
     *
     * @returns {Promise<any>}
     */

    getFclPrice(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-fcl-price', httpOptions)
                .subscribe((response: any) => {
                    this.fclPrice = response;
                    this.onFclPriceChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
    getData(): Promise<any>{
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getFclPriceByFclId(this.id),
                this.fclService.getFclById(this.id),
                this.packageService.getPackageSetting(),
                this.currencyService. getCurrency(),
                this.priceService.getPrice(),
                this.seaportService.getSeaport(),
                this.fclVoyageListService.getVoyageListByFclId(this.id),
                this.vesselService.getVessel(),
                this.voyageService.getVoyage()
            ]).then(
                (data) => {
                    data[0].map(item => {
                        item.price = data[4].find(a => a.id == item.price);
                        item.unit = data[2].find(a => a.id == item.unit);
                        item.currency = data[3].find(a => a.id == item.currency);
                    });
                    data[1][0].pod = data[5].find(a => a.id == data[1][0].pod);
                    data[1][0].pol = data[5].find(a => a.id == data[1][0].pol);
                    //voyage
                    data[6].map(item => {
                        item.vessel = data[7].find(a =>a.id == item.vessel_id);
                        item.pol = data[5].find(a =>a.id == item.pol);
                        item.pod = data[5].find(a =>a.id == item.pod);
                    });
                    this.fclInfo = data;
                    this.onFclInfoPriceChanged.next(data);
                    resolve(data);
                },
                reject
            );
        });
    }
    getFclPriceByFclId(id): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-fcl-price/fcl/'+id)
                .subscribe((response: any) => {
                    this.fclPrice = response;
                    this.onFclPriceChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
}
