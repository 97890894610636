import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { FclService } from 'app/main/service/fcl.service';
import { VoyageService } from 'app/main/service/voyage.service';
import { VesselService } from 'app/main/service/vessel.service';
import { SupplierService } from 'app/main/service/supplier.service';
import { SeaportService } from 'app/main/service/seaport.service';
import { SeaTerminalService } from 'app/main/service/sea-terminal.service';

@Injectable()
export class FclVoyageListService implements Resolve<any>
{
    voyageData;
    voyageList: any[];
    onVoyageListChanged: BehaviorSubject<any>;
    onVoyageDataChanged: BehaviorSubject<any>;
    dataCreate;
    dataUpdate;
    dataDelete;
    
    vessel: any[];
    supplier: any[];
    seaport: any[];
    seaTerminal: any[];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private vesselService: VesselService,
        private supplierService: SupplierService,
        private seaportService: SeaportService,
        private seaTerminalService: SeaTerminalService,
        private voyageService: VoyageService,
        private fclService: FclService,
        private router: Router
    )
    {
        // Set the defaults
        this.onVoyageListChanged = new BehaviorSubject({});
        this.onVoyageDataChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getVoyageList(),
                this.vesselService.getVessel(),
                this.voyageService.getVoyage(),
                this.fclService.getFcl(),
                this.seaportService.getSeaport()
            ]).then(
                (data) => {
                    data[0].map(item => {
                        item.vessel = data[1].find(a =>a.id == item.vessel_id);
                        item.pol = data[4].find(a =>a.id == item.pol);
                        item.pod = data[4].find(a =>a.id == item.pod);
                    });
                    this.voyageList = data[0];
                    this.onVoyageListChanged.next(data[0]);
                    resolve();
                },
                reject
            );
        });
    }
    

    /**
     * Get voyageList
     *
     * @returns {Promise<any>}
     */

    getVoyageList(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-voyage-list')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    getVoyageListByFclId(id): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-voyage-list/fcl/'+id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
