import { Injectable } from '@angular/core';
import { map, catchError} from 'rxjs/operators';
import { 
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument 
} from '@angular/fire/firestore';

import { AngularFireDatabase, FirebaseListObservable } from "@angular/fire/database-deprecated";
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'app/auth.service';

export interface CustomerSearch {
  country_of_loading?: string,
  pol?: string,
  country_of_discharge?: string,
  pod?: string,
  etd?: string,
  type?: string,
  volume?: object,
  profile_id?: number,
  name?: string,
}

@Injectable()

export class DatabaseService {
  databaseCollection: AngularFirestoreCollection<CustomerSearch>;
  database: Observable<CustomerSearch[]>;
  databaseDoc: AngularFirestoreDocument<CustomerSearch>;
  customer;



  constructor(
    private afd: AngularFireDatabase,
    public db:AngularFirestore,
    private authService: AuthService
    ) {
  }

  writeDatabaseCustomerSearch(item){
    let id = Date.now().toString();
    if(this.authService.name != ''){
      item.profile_id = this.authService.profile;
      item.name = this.authService.name;
    } else {
      item.profile_id = 0;
      item.name = 'Guest';
    }
    console.log(item);
    this.databaseCollection = this.db.collection('customer-search');
    this.databaseCollection.doc(id).set(item);
  }
  readDatabaseCustomerSearch(){
    this.databaseCollection = this.db.collection('customer-search');
    this.database = this.databaseCollection.snapshotChanges().pipe(
      map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as CustomerSearch;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.database;
  }

  readDatabase(dataName){
    this.databaseCollection = this.db.collection(dataName);
    this.database = this.databaseCollection.snapshotChanges().pipe(
      map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as CustomerSearch;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.database;
  }
  getQueryCustomerSearch(){
    this.databaseCollection = this.db.collection('customer-search', 
      ref => ref.limit(2));
      //where('short_name', '==', 'VCM'));
    // this.realtime = this.db.collection('realtime').valueChanges();
    this.customer = this.databaseCollection.snapshotChanges(['added','modified','removed']).pipe(
      map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as CustomerSearch;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.customer;
  }

  getCustomerSearch(): FirebaseListObservable<any> {

    return this.afd.list('customer-search', {
              query: {
                orderByKey: true,
                startAt: 1,
                limitToFirst: 2
              }
            });
    }

  getDatabaseCustomerSearchById(id){
    this.databaseCollection = this.db.collection('customer-search');
    
    return this.databaseCollection.doc(id).valueChanges();
  }
  updateDatabaseCustomerSearchById(id, customer){
    this.databaseCollection = this.db.collection('customer-search');
    this.databaseCollection.doc(id).set(customer);
  }
  deleteDatabaseCustomerSearchById(id){
    let path = 'customer-search/' + id;
    this.databaseDoc = this.db.doc(path);
    this.databaseDoc.delete();
  }
}