import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';


@Injectable()
export class VoyageService 
{
    voyage: any[];
    onVoyageChanged: BehaviorSubject<any>;
    dataCreate;
    dataUpdate;
    dataDelete;
    
    vessel: any[];
    supplier: any[];
    seaport: any[];
    seaTerminal: any[];
    vessel_id;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
    )
    {
        // Set the defaults
        this.onVoyageChanged = new BehaviorSubject({});
    }

    getVoyage(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-voyage')
                .subscribe((response: any) => {
                    /*
                    this.voyage = response;
                    this.onVoyageChanged.next(response);
                    */
                    resolve(response);
                }, reject);
        });
    }

    getVoyageByPortId(polId, podId, etd): Promise<any>{
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-voyage-list/port/' + polId + '/' + podId + '/' + etd)
                .subscribe((response: any) => {
                    /*this.voyage = response;
                    this.onVoyageChanged.next(response);
                    */
                    resolve(response);
                }, reject);
        });
    }
}
