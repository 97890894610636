import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';

import { HttpModule } from '@angular/http';

import { AuthGuard } from 'app/auth.guard';
import { AuthService } from 'app/auth.service';
import { FakeDbService } from 'app/fake-db/fake-db.service';


import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database-deprecated';

import { HomeService } from 'app/main/service/home.service';
import { CountryService } from 'app/main/service/country.service';
import { PackageSettingService } from 'app/main/service/package-setting.service';
import { PackageTypeService } from 'app/main/service/package-type.service';
import { VoyageService } from 'app/main/service/voyage.service';
import { VesselService } from 'app/main/service/vessel.service';
import { SupplierService } from 'app/main/service/supplier.service';
import { SeaportService } from 'app/main/service/seaport.service';
import { SeaTerminalService } from 'app/main/service/sea-terminal.service';
import { PriceService } from 'app/main/service/price.service';
import { CurrencyService } from 'app/main/service/currency.service';
import { FclPriceService } from 'app/main/service/fcl-price.service';
import { FclService } from 'app/main/service/fcl.service';
import { DatabaseService } from 'app/main/service/firebase-database.service';
import { FclInfoService } from 'app/main/service/fcl-info.service';
import { FclVoyageListService } from 'app/main/service/fcl-voyage-list.service';
import { UserSearchService } from 'app/main/service/user-search.service';
//import { GoogleSheetService } from 'app/main/service/google-sheet.service';
import { DatePipe } from '@angular/common';

import { FacebookModule } from 'ngx-facebook';

const appRoutes: Routes = [
    {
        path        : 'authentication',
        loadChildren: './main/authentication/authentication.module#AuthenticationModule'
    },
    {
        path        : 'landingpage',
        loadChildren: './main/landingpage/landingpage.module#LandingpageModule'
    },
    {
        path        : 'apps',
        canActivate: [AuthGuard],
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path      : '**',
        //redirectTo: 'authentication/login-2'
        redirectTo: 'landingpage/home2'
        //redirectTo: 'landingpage/fcl-price/34'
    }
/*
  {
    path        : 'authentication',
    loadChildren: './main/authentication/authentication.module#AuthenticationModule'
  },
  
  {
    path        : 'apps',
    canActivate: [AuthGuard],
    loadChildren: './main/apps/apps.module#AppsModule'
  },
  {
      path      : '**',
      //redirectTo: 'authentication/login-2'
      redirectTo: 'landingpage/home'
  },*/
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        RouterModule.forRoot(appRoutes),
        /*,
          {
          enableTracing: true, // <-- debugging purposes only
        }),*/

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        AngularFireStorageModule, // imports firebase/storage only needed for storage features
        AngularFireDatabaseModule,
        
        FacebookModule.forRoot(),
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
      AuthService, 
      AuthGuard,
      DatePipe,
        HomeService,
        CountryService,
        PackageSettingService,
        PackageTypeService,
        VoyageService,
        VesselService,
        SupplierService,
        SeaportService,
        SeaTerminalService,
        FclService,
        FclPriceService,
        PriceService,
        CurrencyService,
        DatabaseService,
        FclInfoService,
        //GoogleSheetService,
        FclVoyageListService,
        UserSearchService
    ]
})
export class AppModule
{
}
