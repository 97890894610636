import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { SupplierService } from 'app/main/service/supplier.service';


@Injectable()
export class VesselService implements Resolve<any>
{
    vessel: any[];
    onVesselChanged: BehaviorSubject<any>;
    dataCreate;
    dataUpdate;
    dataDelete;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router,
        private supplierService: SupplierService,
    )
    {
        // Set the defaults
        this.onVesselChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getVessel(),
                this.supplierService.getSupplier(),
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get vessel
     *
     * @returns {Promise<any>}
     */

    getVessel(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-vessel')
                .subscribe((response: any) => {
                    this.vessel = response;
                    this.onVesselChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }

    getVesselById(id): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/vessel/' + id)
                .subscribe((response: any) => {
                    this.vessel = response;
                    this.onVesselChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }

}
