<div class="theme-options-panel" fusePerfectScrollbar>

    <div class="header">

        <span class="title">Theme Options</span>

        <button mat-icon-button class="close-button" (click)="toggleSidebarOpen('themeOptionsPanel')">
            <mat-icon>close</mat-icon>
        </button>

    </div>

    <form [formGroup]="form">

       

        <!-- LAYOUT STYLES -->
        <div class="group" formGroupName="layout">

            <h2>Layout Styles</h2>

            <!-- DIFFERENT FORMS BASED ON LAYOUT STYLES -->
            <ng-container [ngSwitch]="fuseConfig.layout.style">

                <!-- VERTICAL LAYOUT #1 -->
                <ng-container *ngSwitchCase="'vertical-layout-1'">

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>Navbar</h2>

                        <mat-slide-toggle class="mt-24" formControlName="folded">
                            Folded
                        </mat-slide-toggle>

                        <h3 class="mt-24">Position:</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-16" value="left">Left</mat-radio-button>
                            <mat-radio-button class="mb-16" value="right">Right</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>Toolbar</h2>

                        <mat-slide-toggle formControlName="hidden">
                            Hide
                        </mat-slide-toggle>

                        <h3 class="mt-24">Position:</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">Above</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">Below Static</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">Below Fixed</mat-radio-button>
                        </mat-radio-group>


                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>Footer</h2>

                        <mat-slide-toggle formControlName="hidden">
                            Hide
                        </mat-slide-toggle>

                        <h3 class="mt-24">Position:</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">Above</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">Below Static</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">Below Fixed</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>Side Panel</h2>

                        <mat-slide-toggle formControlName="hidden">
                            Hide
                        </mat-slide-toggle>

                        <h3 class="mt-24">Position:</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="left">Left</mat-radio-button>
                            <mat-radio-button class="mb-12" value="right">Right</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                <!-- VERTICAL LAYOUT #2 -->
                <ng-container *ngSwitchCase="'vertical-layout-2'">


                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>Navbar</h2>

                        <mat-slide-toggle formControlName="hidden">
                            Hide
                        </mat-slide-toggle>

                        <mat-slide-toggle class="mt-24" formControlName="folded">
                            Folded
                        </mat-slide-toggle>

                        <h3 class="mt-24">Position:</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-16" value="left">Left</mat-radio-button>
                            <mat-radio-button class="mb-16" value="right">Right</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>Toolbar</h2>

                        <mat-slide-toggle formControlName="hidden">
                            Hide
                        </mat-slide-toggle>

                        <h3 class="mt-24">Position:</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above-static">Above Static</mat-radio-button>
                            <mat-radio-button class="mb-12" value="above-fixed">Above Fixed</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below">Below</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>Footer</h2>

                        <mat-slide-toggle formControlName="hidden">
                            Hide
                        </mat-slide-toggle>

                        <h3 class="mt-24">Position:</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above-static">Above Static</mat-radio-button>
                            <mat-radio-button class="mb-12" value="above-fixed">Above Fixed</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below">Below</mat-radio-button>
                        </mat-radio-group>

                    </div>


                </ng-container>

                

            </ng-container>

        </div>


    </form>

</div>
