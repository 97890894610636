import { Component } from '@angular/core';
import { AuthService } from 'app/auth.service';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
		version;
    /**
     * Constructor
     */
    constructor( private authService: AuthService)
    {
    	this.version = this.authService.version;
    }
}
