import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { FclService } from 'app/main/service/fcl.service';
import { PackageTypeService } from 'app/main/service/package-type.service';
import { CurrencyService } from 'app/main/service/currency.service';
import { PriceService } from 'app/main/service/price.service';


@Injectable()
export class FclPriceService implements Resolve<any>
{
    fclPrice: any[];
    onFclPriceChanged: BehaviorSubject<any>;
    dataCreate;
    dataUpdate;
    dataDelete;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private fclService: FclService,
        private packageService: PackageTypeService,
        private currencyService: CurrencyService,
        private priceService: PriceService,
        private router: Router
    )
    {
        // Set the defaults
        this.onFclPriceChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getFclPrice(),
                this.fclService.getFcl(),
                this.packageService.getPackageType(),
                this.currencyService. getCurrency(),
                this.priceService.getPrice(),
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get fclPrice
     *
     * @returns {Promise<any>}
     */

    getFclPrice(): Promise<any>
    {
        
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-fcl-price')
                .subscribe((response: any) => {
                    this.fclPrice = response;
                    this.onFclPriceChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
    getFclPriceOF(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-fcl-price/of')
                .subscribe((response: any) => {
                    this.fclPrice = response;
                    this.onFclPriceChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }

}
