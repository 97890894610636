import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { SeaportService } from 'app/main/service/seaport.service';

@Injectable()
export class SeaTerminalService implements Resolve<any>
{
    seaTerminal: any[];
    onSeaTerminalChanged: BehaviorSubject<any>;
    dataCreate;
    dataUpdate;
    dataDelete;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private seaportService: SeaportService,
        private router: Router
    )
    {
        // Set the defaults
        this.onSeaTerminalChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        let id = route.params.id;
        return new Promise((resolve, reject) => {

            Promise.all([
                !!id ? this.getSeaTerminalById(id) : this.getSeaTerminal(),
                this.seaportService.getSeaport()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get seaTerminal
     *
     * @returns {Promise<any>}
     */

    getSeaTerminal(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-sea-terminal')
                .subscribe((response: any) => {
                    response.map(item => item.info != '' ? item.info = JSON.parse(item.info) : item.info = {});
                    this.seaTerminal = response;
                    this.onSeaTerminalChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
    getSeaTerminalById(id): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-sea-terminal/' + id)
                .subscribe((response: any) => {
                    response[0].info != ''  ? response[0].info = JSON.parse(response[0].info) : response[0].info = {};
                    this.seaTerminal = response;
                    this.onSeaTerminalChanged.next(response);
                    
                    resolve(response);
                }, reject);
        });
    }
}
