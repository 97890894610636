import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { SeaportService } from 'app/main/service/seaport.service';

@Injectable()
export class UserSearchService implements Resolve<any>
{
    routeParams: any;
    userSearch: any[];
    onUserSearchChanged: BehaviorSubject<any>;
    port;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private seaportService: SeaportService,
        private router: Router
    )
    {
        // Set the defaults
        this.onUserSearchChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUserSearch(),
                this.seaportService.getSeaport(),
            ]).then(
                (data) => {
                    this.port = data[1];
                    resolve(data);
                },
                reject
            );
        });
    }

    getUserSearch(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/u-user/user-search/' + this.authService.profile, httpOptions)
                .subscribe((response: any) => {
                    let length = response.length;
                    for(let i = 0; i < length; i++){
                        if(response[i].search != '' && response[i].search != 'undefined'){
                            response[i].search = JSON.parse(response[i].search);
                        }
                    }
                    this.userSearch = response;
                    this.onUserSearchChanged.next(this.userSearch);
                    resolve(response);
                }, reject);
        });
    }
    createUserSearch(obj): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        let search = {
            user_profile_id: this.authService.profile,
            data: obj
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/u-user/user-search', search, httpOptions)
                .subscribe((response: any) => {
                    console.log(response);
                    
                    resolve(response);
                }, reject);
        });
    }
}
